import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { isDev } from 'src/utils/env';
import { initReactI18next } from 'react-i18next';

// Get a random hash that is injected on build time.
// Check package.json build script.
// This is to allow simpler Cache-Control in nginx.
const customQueryStringParams =
  !isDev && import.meta.env.VITE_LOCALE_RANDOM_HASH
    ? { v: import.meta.env.VITE_LOCALE_RANDOM_HASH }
    : undefined;

i18n
  .use(Backend) // load translation using xhr -> see /public/locales.
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    debug: false,
    fallbackLng: 'en',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
      format(value, format, lang) {
        if (format === 'formatDate') return Intl.DateTimeFormat(lang).format(value);
        return value;
      },
    },
    defaultNS: 'home',
    ns: [
      'dayPicker',
      'filter',
      'frontpage',
      'header',
      'home',
      'monthPicker',
      'navigation',
      'notificationCenter',
      'pageSettings',
      'pageSettingsNavigation',
      'personalSettingsNavigation',
      'quickCreateNavigation',
      'resourcePlanner',
      'revenueForecast',
      'richTextEditor',
      'savedView',
      'screenHeaderAction',
      'screenHeaderTitle',
      'systemAdministration',
      'systemAdministrationNavigation',
      'systemAdminExchangeRate',
      'reportingWidgets',
      'viewOptions',
    ],
    backend: {
      queryStringParams: customQueryStringParams,
    },
  });

export default i18n;
