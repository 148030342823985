import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from 'src/components/mui-components';
import DoneIcon from '@mui/icons-material/Done';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { convertNumber, validateNumberInput } from 'src/utils/number';
import { stringToPascal } from 'src/utils/string';
import { formatTime } from 'src/utils/time';
import { IPeriodColumn, RPRow } from '../../types/resourcePlanner';
import styles from './CustomizedCell.module.scss';

type CustomizedCellProps = {
  row: RPRow;
  column: IPeriodColumn;
  value: string;
  isEditable: boolean;
  onCellValueChange: (row: RPRow, column: IPeriodColumn, value: string) => void;
  unitType: string;
  reportingType: string;
};

export const CustomizedCell = ({
  row,
  column,
  value,
  isEditable,
  onCellValueChange,
  unitType,
  reportingType,
}: CustomizedCellProps) => {
  const [editValue, setEditValue] = useState(value);
  const [error, setError] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const { t } = useTranslation('resourcePlanner');
  const siteLocale = useGetLocale();
  const actualValueInt = parseFloat(value);

  useEffect(() => {
    const fullValue = actualValueInt.toLocaleString(siteLocale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    setEditValue(fullValue);
  }, [siteLocale, actualValueInt]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    const { status, value: outputValue } = validateNumberInput(
      editValue,
      t('UseDecimalFormatValidationFeedback'),
    );
    if (status === 'error') {
      setError(outputValue);
      return;
    }
    const decimalFormat = formatTime(outputValue, siteLocale);
    const currentValueInt = convertNumber(decimalFormat, siteLocale);

    setEditValue(decimalFormat.toString());

    if (decimalFormat !== '0,00') {
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
      }, 2000);
    }

    setError('');
    onCellValueChange(row, column, currentValueInt.toString());
  }, [editValue, t, siteLocale, onCellValueChange, row, column]);

  const onFocus = () => {
    setIsSaved(false);
  };

  const isUnitTypePercentage = unitType && unitType === 'percentages';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
      {isEditable ? (
        <>
          <TextField
            hiddenLabel
            ariaLabel={`tableRow${row.id} tableColumn${stringToPascal(column?.identifier)}`}
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={onFocus}
            value={value === '0' || editValue === '0,00' ? '' : editValue}
            errorMessage={error}
            usePopOutValidation
            status="error"
            className={classNames(styles.customizedCell, {
              [styles.saved]: isSaved,
            })}
            data-automation-id="ExchangeRateTableCellEditInput"
          />
          {isSaved && (
            <div className={styles.inputSavedIcon}>
              <DoneIcon fontSize="small" />
            </div>
          )}
        </>
      ) : (
        <div>
          {reportingType === 'availability' || value ? formatTime(value || 0, siteLocale) : ''}
        </div>
      )}
      {isUnitTypePercentage && (isEditable || value) ? (
        <span className={styles.unitTypeIcon}>%</span>
      ) : (
        ''
      )}
    </Box>
  );
};
