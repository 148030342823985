import { RPinvalidateOldQueryOnViewOptionChange } from '../localStorageKeys';

// eslint-disable-next-line react-hooks/exhaustive-deps
export const expandRows = (apiRef: any) => {
  const rowIds = apiRef.current.getAllRowIds();

  const allRowNode = rowIds.map((id: any) => apiRef.current.getRowNode(id));

  const maxDepth = allRowNode.reduce((max: any, row: any) => {
    if (row.children && row.children.length > 0 && row.childrenExpanded) {
      return Math.max(max, row.depth);
    }
    return max;
  }, -1);

  const minDepth = allRowNode.reduce((min: any, row: any) => {
    if (row.children && row.children.length > 0 && !row.childrenExpanded) {
      return Math.min(min, row.depth);
    }
    return min;
  }, maxDepth);

  const filteredRowToCollapse = allRowNode.filter(
    (row: any) => row.type !== 'leaf' && row.depth > minDepth && row.childrenExpanded,
  );

  let allShouldExpandRowId = allRowNode.filter(
    (row: any) => row.type !== 'leaf' && row.depth === minDepth && !row.childrenExpanded,
  );

  // need a function to get all childrenExpanded = true
  const isAllChildExpanded = allRowNode.filter(
    (row: any) => row.type !== 'leaf' && !row.childrenExpanded,
  );

  if (allShouldExpandRowId?.length <= 0 && isAllChildExpanded?.length > 0) {
    allShouldExpandRowId = allRowNode.filter(
      (row: any) => row.type !== 'leaf' && row.depth === minDepth + 1 && !row.childrenExpanded,
    );
  }

  filteredRowToCollapse.forEach((row: any) => {
    apiRef.current.setRowChildrenExpansion(row.id, false);
  });

  allShouldExpandRowId.forEach((row: any) => {
    apiRef.current.setRowChildrenExpansion(row.id, true);
  });
  localStorage.setItem(RPinvalidateOldQueryOnViewOptionChange, 'true');
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const collapseRow = (apiRef: any) => {
  const rowIds = apiRef.current.getAllRowIds();

  const allRowNode = rowIds.map((id: any) => apiRef.current.getRowNode(id));

  const maxDepth = allRowNode.reduce((max: any, row: any) => {
    if (row.children && row.children.length > 0 && row.childrenExpanded) {
      return Math.max(max, row.depth);
    }
    return max;
  }, -1);

  const allShouldCollapseRowId = allRowNode.filter(
    (row: any) => row.type !== 'leaf' && row.depth === maxDepth,
  );

  allShouldCollapseRowId.forEach((row: any) => {
    apiRef.current.setRowChildrenExpansion(row.id, false);
  });
  localStorage.setItem(RPinvalidateOldQueryOnViewOptionChange, 'true');
};

// Calculate the max width of the Grouping column, and manually set the width
// Pending official fix https://github.com/mui/mui-x/issues/10848
export const calculateMaxGroupingColumnWidth = () => {
  const groupingCells = document.querySelectorAll(
    '.MuiDataGrid-pinnedColumns .MuiDataGrid-row .MuiDataGrid-treeDataGroupingCell',
  );
  let maximumWidth = 0;
  groupingCells.forEach((cell) => {
    const htmlCell = cell as HTMLElement;
    const cellStyle = window.getComputedStyle(htmlCell);
    const cellPaddingLeft = parseFloat(cellStyle.paddingLeft);
    const firstContentCell = htmlCell.querySelector('.MuiBox-root:nth-child(1)') as HTMLElement;
    const secondContentCell = htmlCell.querySelector('.MuiBox-root:nth-child(2)') as HTMLElement;
    const secondContentCellWidth = secondContentCell ? secondContentCell.clientWidth + 40 : 40;
    const firstContentCellStyle = firstContentCell
      ? window.getComputedStyle(firstContentCell)
      : null;
    const firstContentCellPaddingLeft = firstContentCellStyle
      ? parseFloat(firstContentCellStyle.paddingLeft)
      : 0;
    let firstContentCellChildrenWidth = 0;
    if (firstContentCell) {
      const { children: contentCell } = firstContentCell;
      for (let i = 0; i < contentCell.length; i += 1) {
        const child = contentCell[i] as HTMLElement;
        firstContentCellChildrenWidth += child.clientWidth;
      }
    }
    const totalWidth =
      cellPaddingLeft +
      firstContentCellPaddingLeft +
      firstContentCellChildrenWidth +
      secondContentCellWidth;
    if (totalWidth > maximumWidth) {
      maximumWidth = totalWidth;
    }
  });
  return maximumWidth;
};
