/* eslint-disable @typescript-eslint/no-unused-vars */
import { getDateStringFromSiteLocale } from 'src/utils/date';
import { formatTime } from 'src/utils/time';

interface GeneralColumnRendererProps {
  id?: string | undefined;
  format?: string | undefined;
  row: any;
  value: string;
  siteLocale: any;
}

export const GeneralColumnRendererMui = ({
  id,
  format,
  row,
  value,
  siteLocale,
}: GeneralColumnRendererProps) => {
  if ((id === 'startsAt' || id === 'endsAt') && value) {
    let dateString = '';

    if (id === 'startsAt' && row.startsAt) {
      dateString = row.startsAt.substring(0, row.startsAt.indexOf('T'));
    }

    if (id === 'endsAt' && row.endsAt) {
      dateString = row.endsAt.substring(0, row.endsAt.indexOf('T'));
    }

    if (dateString) {
      const [year, month, day] = dateString.split('-');

      const monthString = month.length === 1 ? `0${month}` : month;
      const dayString = day.length === 1 ? `0${day}` : day;

      const date = new Date(`${year}-${monthString}-${dayString}T00:00:00`);
      return getDateStringFromSiteLocale(date, siteLocale);
    }
  }

  if (id === 'status' && row.translatedStatus) {
    return row.translatedStatus;
  }

  if (format === 'number') {
    const formatTimeValue = value ? formatTime(value, siteLocale) : formatTime('0', siteLocale);
    return formatTimeValue;
  }

  return value === undefined ? '' : value;
};
